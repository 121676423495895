<script setup lang="ts">
const model = defineModel<boolean>();

/**
 * Входные параметры (props).
 * @property {string} title - Заголовок для компонента.
 * @property {number} maxWidth - Максимальная ширина диалога (по умолчанию 500).
 * @property {number} maxHeight - Максимальная высота диалога (по умолчанию 700).
 * @property {boolean} showButton - Отображение кнопки (по умолчанию false).
 */
const props = defineProps({
    title: { type: String, required: true, default: '' },
    maxWidth: {type: Number, required: false, default: 500},
    maxHeight: {type: Number, required: false, default: 700},
    showButton: {type: Boolean, required: false, default: false},
});

/**
 * Определение событий для взаимодействия с родительским компонентом.
 * @emits closeDialog - Событие, которое вызывается для закрытия диалога.
 */
const emit = defineEmits(['closeDialog']);

/**
 * Функция для закрытия диалога.
 * Вызывает событие 'closeDialog', чтобы уведомить родительский компонент о закрытии.
 */
const onCancel = (): void => {
    emit('closeDialog');
};
</script>

<template>
    <v-dialog 
        v-model="model"
        :maxWidth='props.maxWidth'
        :maxHeight='props.maxHeight'
    >
        <template #default>
            <v-card :title="props.title">
                <v-btn
                    v-if="props.showButton"
                    class="close-popup"
                    size="x-large"
                    elevation="0"
                    @click="onCancel"
                >
                    <i class="ri-close-large-line"></i>
                </v-btn>

                <v-card-text>
                    <slot name="content" />
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>

                    <slot name="actions"/>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

<style scoped lang="scss">
.close-popup {
    position: absolute !important;
    right: 10px;
    top: 15px;
    transform: translateZ(0) scale(0.85);
    will-change: transform;
}

.close-popup .mdi {
    font-size: 24px;
}
div.v-card {
    position: relative !important;
}

.v-card-actions {
    padding: 12px 24px 24px;

    @include responsive( 'mobile' ) {
        flex-wrap: wrap;
    }
}
</style>
